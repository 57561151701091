<template>
  <df-modal
    persistent
    width="640px"
    :action-name="$t('SowingView.growth_stage_modal.save')"
    :loading="loading"
    :title="$t('SowingView.growth_stage_modal.edit_growth_stage')"
    @action-click="editGrowthStage"
    @close="closeGrowthStageModal"
  >
    <template #card-content>
      <v-form ref="growthStageForm">
        <df-select
          v-model="editedGrowthStage"
          :items="growthStages"
          :label="$t('SowingView.growth_stage_modal.growth_stage')"
          :rules="[required]"
        />
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
import growthStage from '@/services/growthStage'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import { required } from '@/utils/formRules'

export default {
  name: 'GrowthStageModal',

  components: {
    DfModal,
    DfSelect,
  },

  props: {
    cropName: {
      required: true,
      type: String,
    },
    growthStage: {
      required: true,
      type: String,
    },
    sowingId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      editedGrowthStage: {},
      loading: false,
      required,
    }
  },

  inject: {
    requestSowingInfos: {
      from: 'requestSowingInfos',
    },
  },

  mounted() {
    this.editedGrowthStage = {
      name: this.description(this.growthStage),
      value: this.growthStage.toUpperCase(),
    }
  },

  computed: {
    growthStages() {
      return growthStage[this.cropName].stages.map((stage) => {
        return {
          name: this.description(stage.label),
          value: stage.value,
        }
      })
    },
  },

  methods: {
    closeGrowthStageModal() {
      this.$emit('close')
    },
    description(currentGrowthStage) {
      const progressSteps =
        growthStage[this.cropName.toLowerCase()].progressSteps
      let result = []
      if (!currentGrowthStage) {
        result = Object.keys(progressSteps)
      } else {
        result = Object.entries(progressSteps).find(([_, values]) =>
          values.includes(currentGrowthStage.toUpperCase())
        )
      }
      if (result && result.length) {
        return `${currentGrowthStage.toUpperCase()} - ${this.$t(
          'SowingView.sowing_growth_stage.stage'
        )} ${this.$t(
          `SowingView.sowing_growth_stage.${result[0]}`
        ).toLowerCase()}`
      }
      return 'Full'
    },
    async editGrowthStage() {
      if (!this.$refs.growthStageForm.validate()) return
      this.loading = true
      const params = {
        growth_stage: this.editedGrowthStage.value,
        sowing_id: this.sowingId,
      }
      try {
        await sowingViewService.editGrowthStage(params)
        this.requestSowingInfos()
        this.closeGrowthStageModal()
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingView.saved_changes')
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
